import React, {Component} from 'react';
import {urltemizle} from '../../components/utils';
import CartEmpty from '../cart/cartempty';
import '../../assets/css/payment.css';
import Stepper from '../../components/stepper/stepper.js';
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import swal from 'sweetalert2';

export class DeliveryDetails extends Component {
    constructor(props) {
        super(props);
        this.getProvinces = this.getProvinces.bind(this);
        this.getCities = this.getCities.bind(this);
        this.handleDeliveryProvinceChange = this.handleDeliveryProvinceChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    state = {
        changed: false,
        isLoading: false,
        isOpen: false,
        activeModal: null,
        cart_id: '',
        cart_total_buyer: '',
        cart_items: [],
        active_id: '',
        iller: [],
        deliverycities: [],
        mahalleler: [],
        deliveryPerson: '',
        deliveryPhone: '',
        deliveryAddress: '',
        deliveryCountry: 'Türkiye',
        deliveryProvince: '',
        deliveryCity: '',
        deliveryPostalCode: '',
        additionalNotes: '',
        useraddresses: [],
        selectedAddress: 0,
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    openModal = () => this.setState({isOpen: true});
    closeModal = () => this.setState({isOpen: false, activeModal: null});
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, changed: true});
    }

    handleDeliveryProvinceChange = (event) => {
        this.setState({[event.target.name]: event.target.value, changed: true});
        this.getCities(event.target.value);
    }

    handleAddressChange = async (event) => {
        console.log("handleAddressChange event");
        console.log(event);
        console.log(event.target.value);

        this.setState({
            selectedAddress: event.target.value
        });

        const theaddress = this.state.useraddresses.filter((item) => item.id == event.target.value);

        console.log("theaddress");
        console.log(theaddress);

        await this.getCities(theaddress[0].city);

        await this.setState({
            deliveryPerson: theaddress[0].first_name + " " + theaddress[0].last_name,
            deliveryPhone: theaddress[0].phone_1,
            deliveryAddress: theaddress[0].line1,
            deliveryCity: theaddress[0].town,
            deliveryProvince: theaddress[0].city,
            deliveryPostalCode: theaddress[0].postalcode,
            deliveryCountry: theaddress[0].country,
        });

        // users_address:
        //     title
        //     line1
        //     line2
        //     town
        //     city
        //     postalcode
        //     country

        // formData.append('delivery_contact_name', this.state.deliveryPerson)
        // formData.append('delivery_contact_phone', this.state.deliveryPhone)
        // formData.append('delivery_address', this.state.deliveryAddress)
        // formData.append('delivery_town', this.state.deliveryCity)
        // formData.append('delivery_city', this.state.deliveryProvince)
        // formData.append('delivery_postalcode', this.state.deliveryPostalCode)
        // formData.append('delivery_country', this.state.deliveryCountry)
        // formData.append('additional_notes', this.state.additionalNotes)
    };

    getProvinces() {
        fetch(this.props.apiurl + '/api/iller', {method: 'GET', credentials: 'include'})
            .then(res => res.json())
            .then((data) => {
                    this.setState({iller: data});
                }
            )
            .catch(err => {
                this.setState({errorDesc: err});
            })
    }

    getCities(province) {
        fetch(this.props.apiurl + '/api/ilceler/' + province, {method: 'GET', credentials: 'include'})
            .then(res => res.json())
            .then((data) => {
                    this.setState({deliverycities: data});
                }
            )
            .catch(err => {
                this.setState({errorDesc: err});
            })
    }

    getUsersAddresses = async () => {
        console.log("getUsersAddresses");

        var fetchURL = "/api/MezatVar/FetchAddressesByCartId";
        var postData = this.state;

        postData["cartsId"] = this.props.veri.id;

        await fetch(fetchURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {
                    console.log("getUsersAddresses Succeeded");
                    this.setState({
                        useraddresses: result.useraddresses
                    });
                } else {
                    console.log("getUsersAddresses Error");
                }
            });
    }

    handleSubmit = event => {
        event.preventDefault();
        var retype = 'btn-green';
        this.setState({isOpen: false});
        if (this.state.changed) {
            const {cookies} = this.props;
            var formData = new FormData()

            formData.append('delivery_contact_name', this.state.deliveryPerson)
            formData.append('delivery_contact_phone', this.state.deliveryPhone)
            formData.append('delivery_address', this.state.deliveryAddress)
            formData.append('delivery_town', this.state.deliveryCity)
            formData.append('delivery_city', this.state.deliveryProvince)
            formData.append('delivery_postalcode', this.state.deliveryPostalCode)
            formData.append('delivery_country', this.state.deliveryCountry)
            formData.append('additional_notes', this.state.additionalNotes)

            fetch(this.props.apiurl + '/api/updateCartDelivery', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + cookies.get('access-token'),
                },
                credentials: 'include',
                body: formData
            })
                .then((response) => {
                    if (!response.ok) {
                        retype = 'error';
                        return response.json();
                    } else {
                        retype = 'success';
                        return response.json();
                    }
                })
                .then((responseData) => {
                    if (responseData.message === 'Unauthenticated.') {
                        swal.fire({
                            icon: "error",
                            text: 'Teslimat bilgileri üzerinde değişiklik yapmak için üye girişi yapmalısınız',
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    } else {
                        if (retype === 'error') {
                            swal.fire({
                                icon: retype,
                                text: responseData.message,
                                timer: 5000,
                                showConfirmButton: false,
                                showCancelButton: false,
                                showCloseButton: true,
                                closeButtonCaption: 'Kapat'
                            });
                        } else {
                            window.location = '/fatura';
                        }
                    }
                })

                .catch((error) => {
                    swal.fire({
                        icon: 'error',
                        text: 'Bir hata oluştu. ' + error,
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                });
        } else {
            window.location = '/fatura';
        }
    }

    componentDidMount() {
        this.props.veri &&
        this.setState({
            cart_id: this.props.veri.id,
            cart_total_buyer: this.props.veri.cart_total_buyer,
            cart_items: this.props.veri.cart_item,
            items_total: this.props.veri.items_total,
            commissions_total_buyer: this.props.veri.commissions_total_buyer,
            deliveryPerson: this.props.veri.delivery_contact_name,
            deliveryPhone: this.props.veri.delivery_contact_phone,
            deliveryAddress: this.props.veri.delivery_address,
            deliveryCity: this.props.veri.delivery_town != null ? this.props.veri.delivery_town.toLocaleUpperCase() : '',
            deliveryProvince: this.props.veri.delivery_city != null ? this.props.veri.delivery_city.toLocaleUpperCase() : '',
            deliveryPostalCode: this.props.veri.delivery_postalcode,

            additionalNotes: this.props.veri.additional_notes
        })

        this.getCities(this.props.veri.delivery_city != null ? this.props.veri.delivery_city.toLocaleUpperCase() : '');
        this.getProvinces();

        this.getUsersAddresses();

        console.log("delivery details component mount");
        console.log(this.props);
    }


    render() {

        if (this.state.cart_items.length === 0) {
            return <CartEmpty/>
        } else {
            return (
                <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-left">
                    <div className="row  pt-2 pb-0">
                        <div className="col-md-12">
                            <Stepper steps={["Teslimat Bilgileri", "Fatura Bilgileri", "Ödeme Bilgileri", "Özet"]}
                                     activestep={1}/>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="container px-1 px-md-2 px-lg-4 mx-auto text-dark">
                                    <h3 className="mb-1 text-uppercase text-primary">Teslimat Bilgileri </h3>
                                    <p className="smalltext">(*) ile işaretli alanların doldurulması zorunludur</p>
                                    <label htmlFor="selectaddress">Adres Seçiniz:</label>
                                    <select name="selectaddress" id="selectaddress" value={this.state.selectedAddress}
                                            onChange={this.handleAddressChange.bind(this)}>
                                        <option value="0">Yeni Adres</option>
                                        {/*<option value="1">Adres 1</option>*/}
                                        {this.state.useraddresses && this.state.useraddresses.map((address, index) => (
                                            <option
                                                value={address.id}>{address.line1}</option>
                                        ))}
                                    </select>
                                    <div className="row d-flex">
                                        <div className="col-sm-6">
                                            <div className="border-0">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0">Teslim alacak kişi
                                                            (*)</p>
                                                        <input className="form-control text-uppercase" type="text"
                                                               name="deliveryPerson"
                                                               placeholder="Teslim alacak kişinin adı ve soyadı"
                                                               size="50" value={this.state.deliveryPerson}
                                                               onChange={this.handleChange} required/>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0"> Ülke (*)</p>
                                                        <select className="form-select form-control" size="1"
                                                                placeholder="Ülke" name="deliveryCountry"
                                                                id="deliveryCountry" onChange={this.handleChange}>
                                                            <option key="" value="Türkiye">TÜRKİYE</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0"> İlçe (*)</p>
                                                        <select className="form-select form-control"
                                                                value={this.state.deliveryCity}
                                                                defaultValue={this.state.deliveryCity} size="1"
                                                                placeholder="İlçe" name="deliveryCity"
                                                                id="deliveryCity"
                                                                onChange={this.handleChange} required>
                                                            <option key="" value="" selected disabled hidden>Lütfen
                                                                bir
                                                                ilçe seçin
                                                            </option>

                                                            {this.state.deliverycities && this.state.deliverycities.map((e, key) => {
                                                                return <option key={key}
                                                                               value={e.ilce}>{e.ilce}</option>;
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <p className="font-weight-bold mb-0"> Teslimat Adresi
                                                            (*)</p>
                                                        <textarea name="deliveryAddress"
                                                                  placeholder="Teslimat yapılacak açık adresi giriniz"
                                                                  value={this.state.deliveryAddress}
                                                                  className="form-control col-md-12"
                                                                  onChange={this.handleChange} required/>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="border-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0">Teslim alacak
                                                                kişinin
                                                                telefonu</p> <input
                                                            className="form-control text-uppercase" type="text"
                                                            name="deliveryPhone" value={this.state.deliveryPhone}
                                                            placeholder="Teslim alacak kişinin telefonu" size="15"
                                                            onChange={this.handleChange}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0"> İl (*)</p>
                                                            <select className="form-select form-control" size="1"
                                                                    value={this.state.deliveryProvince}
                                                                    defaultValue={this.state.deliveryProvince}
                                                                    placeholder="İl" name="deliveryProvince"
                                                                    id="deliveryProvince"
                                                                    onChange={this.handleDeliveryProvinceChange}
                                                                    required>
                                                                <option key="" value="" selected disabled
                                                                        hidden>Lütfen
                                                                    bir il seçin
                                                                </option>
                                                                {this.state.iller && this.state.iller.map((e, key) => {
                                                                    return <option key={key}
                                                                                   value={e.il}>{e.il}</option>;
                                                                })}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0">Posta Kodu</p>
                                                            <input
                                                                className="form-control" type="text"
                                                                name="deliveryPostalCode"
                                                                value={this.state.deliveryPostalCode}
                                                                placeholder="Teslimat adresine ait posta kodu"
                                                                size="6"
                                                                minLength="5" maxLength="7"
                                                                onChange={this.handleChange}/>
                                                        </div>

                                                        <div className="form-group">
                                                            <p className="font-weight-bold mb-0"> Teslimat Notu</p>
                                                            <textarea name="additionalNotes"
                                                                      placeholder="Teslimata dair notunuz varsa giriniz"
                                                                      value={this.state.additionalNotes}
                                                                      className="form-control col-md-12"
                                                                      onChange={this.handleChange}/>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="ibox">
                                    <div className="ibox-title text-left">
                                        <h5>Sepet Özeti</h5>
                                    </div>
                                    <div className="ibox-content text-center">
                                    <span>
                                        Ödenecek Tutar
                                    </span>
                                        <h2 className="font-bold text-center">
                                            {this.numberFormat(this.state.cart_total_buyer)} ₺
                                        </h2>

                                        <hr/>

                                        <div className="m-t-sm  text-center">
                                            <div className="btn-group">
                                                <button type="submit" className="btn btn-blue btn-sm"><i
                                                    className="fa fa-credit-card"></i> Fatura Aşamasına Geç
                                                </button>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="text-dark small">
                                            <div className="row">
                                                <div className="col-md-5 text-left">Ürün toplamı</div>
                                                <div className="col-md-1">:</div>
                                                <div
                                                    className="col-md-5 text-right">{this.numberFormat(this.state.items_total)} ₺
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 text-left">Hizmet bedeli</div>
                                                <div className="col-md-1">:</div>
                                                <div
                                                    className="col-md-5 text-right">{this.numberFormat(this.state.commissions_total_buyer)} ₺
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 text-left">Kargo bedeli</div>
                                                <div className="col-md-1">:</div>
                                                <div
                                                    className="col-md-5 text-right">{this.numberFormat(this.state.shipping_price_total_buyer)} ₺
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>

                                <div className="ibox">
                                    <div className="ibox-title">
                                        <h5>Destek</h5>
                                    </div>
                                    <div className="ibox-content text-center">
                                        <h3><i className="fa fa-phone"></i> <a href="tel:0850 840 44 94">0850 840 44
                                            94</a></h3>
                                        <span className="small">
                                        Aklınıza takılan tüm sorular için 24 saat hizmetinizdeyiz
                                    </span>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 p-4">
                                <div className="col-sm-6 float-left">
                                    <a href="/sepetim" className="btn btn-white"><i
                                        className="fa fa-arrow-left"></i> Sepete Geri Dön</a>
                                </div>
                                <div className="col-sm-6 float-right">
                                    <button type="submit" className="btn btn-blue pull-right"><i
                                        className="fa fa fa-credit-card"></i> Fatura Aşamasına Geç <i
                                        className="fa fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            )
        }
    }

}

export default withCookies(DeliveryDetails);